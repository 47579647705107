import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <nav className="navbar navbar-expand-lg navbar-light green-header-bar">
    <div className="container-fluid">
      <div className="navbar-header">
        <a className="navbar-brand green-header-bar-text" href="#">
          {siteTitle}
        </a>
      </div>
      <ul className="nav ml-auto ">
        <li className="nav-item">
          <Link className="nav-link green-header-bar-text" to="/">
            General Info
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link green-header-bar-text" to="/faqs">
            FAQs
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link green-header-bar-text" to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
  </nav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
