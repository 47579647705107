import { Link, Img } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import PwdBy from "../../images/hmp-logo-powered-by-green.png"

const Footer = ({ siteTitle }) => (
  <nav className="navbar navbar-expand-lg green-header-bar mb-3">
    <div className="container-fluid">
      <div className="navbar-header">
        <a className="navbar-brand green-header-bar-text" href="#">
          {siteTitle}
        </a>
      </div>
      <ul className="nav ml-auto ">
        <li className="nav-item">
          <Link className="nav-link green-header-bar-text" to="/page-2/"></Link>
        </li>
      </ul>
      <Link to="https://hmpmetrics.com" target="_blank">
        <img
          src={PwdBy}
          style={{ width: "175px" }}
          alt="Powered by HMP Metrics"
        />
      </Link>
    </div>
  </nav>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
