/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "bootstrap/dist/css/bootstrap.min.css"

import Header from "./header"
import Footer from "./footer"

import "./layout.css"

import SideMenu from "./side-menu"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="container-fluid p-0 site">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="row site-content">
        <div className="col-3">
          <SideMenu className="" />
        </div>
        <div className="col-9 pt-3 ">
          <main className="">{children}</main>
        </div>
      </div>
      <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
