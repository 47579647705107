import React from "react"
import "./side-menu.css"
import { Link } from "gatsby"
import { SideMenuItems } from "./side-menu-items"

export default class SideMenu extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let MenuItems = []

    SideMenuItems.forEach(x => {
      MenuItems.push(<Link to={x.Url}>{x.title}</Link>)
    })

    return (
      <div
        className="vertical-menu m-3 side-menu Regular shadow border"
        style={{ height: "200 rem" }}
      >
        {MenuItems}
      </div>
    )
  }
}
