import React from "react"
import Layout from "../../components/layout/layout"
import { Link } from "gatsby"
export default class Contact extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <div className="">
          <h3>Key Court Documents</h3>
          <p>
            <em>Below are certain pleadings from the case.</em>
          </p>
          <p>
            <strong>12/22/2020</strong>
            <br/>
            <Link
              target="_blank"
              to="../wp-content/documents/Dkt-414-Notice-of-Final-Distribution-4827-6525-8965.pdf"
            >
              Docket No. 415 - DECLARATION OF SERVICE <br />
            </Link>

          </p>
          <p>
            <strong>12/20/2020</strong>
            <br/>
            <Link
              target="_blank"
              to="../wp-content/documents/Dkt-414-Notice-of-Final-Distribution-4827-6525-8965.pdf"
            >
              Docket No. 414 - RECEIVER’S NOTICE OF FINAL DISTRIBUTION <br />
            </Link>

          </p>

          <p>
            <strong>10/16/2020</strong>
            <br/>
            <Link
              target="_blank"
              to="../wp-content/documents/HMP-SEC-Receivership-October16LettertoCourt-dkt-412.pdf"
            >
              Docket No. 412 - HMP - SEC Receivership Letter to the Court
              <br />
            </Link>

          </p>

          <p>
          <strong>7/2/2020</strong>
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.10-Waller-Tenth-Quarterly-Fee-Application-Dkt.-No.-343.pdf"
            >
              Docket No. 343 - Waller's Tenth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.9-Waller-Ninth-Quarterly-Fee-Application-Dkt.-No.-324.pdf"
            >
              Docket No. 324 - Waller's Ninth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.8-Waller-Eighth-Quarterly-Fee-Application-Dkt.-No.-321.pdf"
            >
              Docket No. 321 - Waller's Eighth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.7-Waller-Seventh-Quarterly-Application-Dkt.-No.-300.pdf"
            >
              Docket No. 300 - Waller's Seventh Quarterly Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.6-Waller-Sixth-Quarterly-Fee-Application-Dkt.-No.-278.pdf"
            >
              Docket No. 278 - Waller Sixth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.5.a-Waller-Fifth-Quarterly-Fee-Application-Supplement-Dkt.-No.-272.pdf"
            >
              Docket No. 272 - Waller's Fifth Quarterly Fee Application -
              Supplement
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.5-Waller-Fifth-Quarterly-Fee-Application-Dkt.-No.-270.pdf"
            >
              Docket No. 270 - Waller's Fifth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.4-Waller-Fourth-Quarterly-Fee-Application-Dkt.-No.-228.pdf"
            >
              Docket No. 228 - Waller's Fourth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.3-Waller-Third-Quarterly-Fee-Application-Dkt.-No.-150.pdf"
            >
              Docket No. 150 - Waller's Third Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.2-Waller-Second-Quarterly-Fee-Application-Dkt.-No.-132.pdf"
            >
              Docket No. 132 - Waller's Second Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/w.1-Waller-First-Quarterly-Fee-Application-Dkt.-No.-117.pdf"
            >
              Docket No. 117 - Waller's First Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.10HMP-Tenth-Quarterly-Fee-Application-Dkt.-No.-342.pdf"
            >
              Docket No. 342 - HMP's Tenth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.9-HMP-Ninth-Quarterly-Fee-Application-Dkt.-No.-323.pdf"
            >
              Docket No. 323 - HMP's Ninth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.8-HMP-Eighth-Quarterly-Fee-Application-Dkt.-No.-320.pdf"
            >
              Docket No. 320 - HMP's Eighth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.7-HMP-Seventh-Quarterly-Fee-Application-Dkt.-No.-302.pdf"
            >
              Docket No. 302 - HMP's Seventh Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.6-HMP-Sixth-Quarterly-Fee-Application-Dkt.-No.-279.pdf"
            >
              Docket No. 279 - HMP's Sixth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.5-HMP-Fifth-Quarterly-Fee-Application-Dkt.-No.-268.pdf"
            >
              Docket No. 268 - HMP's Fifth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.4-HMP-Fourth-Quarterly-Fee-Application-Dkt.-No.-229.pdf"
            >
              Docket No. 229 - HMP's Fourth Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.3-HMP-Third-Quarterly-Fee-Application-Dkt.-No.-149.pdf"
            >
              Docket No. 149 - HMP's Third Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.2-HMP-Second-Quarterly-Fee-Application-Dkt.-No.-133.pdf"
            >
              Docket No. 133 - HMP's Second Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/h.1-HMP-First-Quarterly-Fee-Application-Dkt.-No.-116.pdf"
            >
              Docket No. 116 - HMP's First Quarterly Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-409-Brief-in-Support-of-Motion-to-Discharge-Receiver.pdf"
            >
              Docket No. 409 - Brief in Support of Motion to Discharge Receiver
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-408-Motion-to-Discharge-Receiver.pdf"
            >
              Docket No. 408 - Motion to Discharge Receiver
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-407-Notice-of-DeFrain-and-Millions-Final-Fee-Application.pdf"
            >
              Docket No. 407 - Notice of DeFrain and Million's Final Fee
              Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-406-DeFrain-and-Millions-Final-Fee-Application.pdf"
            >
              Docket No. 406 - DeFrain and Million's Final Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-405-Notice-of-Elliott-Daviss-Final-Fee-Application.pdf"
            >
              Docket No. 405 - Notice of Elliott Davis's Final Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-404-Elliott-Daviss-Final-Fee-Application.pdf"
            >
              Docket No. 404 - Elliott Davis's Final Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-403-Notice-of-Wallers-Final-Fee-Application.pdf"
            >
              Docket No. 403 - Notice of Waller's Final Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-402-Wallers-Final-Fee-Application.pdf"
            >
              Docket No. 402 - Waller's Final Fee Application 
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-401-Notice-of-Healthcare-Management-Partners-Final-Fee-Application.pdf"
            >
              Docket No. 401 - Notice of Healthcare Management Partner's Final
              Fee Application
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/07/Dkt.-No.-400-Healthcare-Management-Partners-Final-Fee-Application.pdf"
            >
              Docket No. 400 - Healthcare Management Partner's Final Fee
              Application
              <br />
            </Link>
          </p>

          <p>
            3/4/20
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2020/03/Order-Approving-Claims-Report.pdf"
            >
              Order Approving Claims Report
              <br />
            </Link>
          </p>
          <p>
            6/27/19
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/06/Dkt.-No.-330-Order-Granting-Preliminary-Approval-of-Settlement-Motion.pdf"
            >
              Docket No. 330 - Order Granting Preliminary Approval of Settlement
              Motion
              <br />
            </Link>
          </p>
          <p>
            5/24/19
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2019/05/SEC-Settlement-Mtn.pdf"
            >
              Settlement Motion 5.24.19
              <br />
            </Link>
          </p>

          <p>
            10/1/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-290-ORDER-granting-281-Motion-for-Disbursement-of-Funds-re-COLUMBUS-ALF-LLC-SALE-4846-5.pdf"
            >
              Docket No. 290 - Columbus Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-291-ORDER-granting-282-Motion-for-Disbursement-of-Funds-re-OXTON-PLACE-OF-DOUGLAS-4831-7.pdf"
            >
              Docket No. 291 - Douglas Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-292-ORDER-granting-283-Motion-for-Disbursement-of-Funds-re-GAINESVILLE-ALF-LLC-4832-267.pdf"
            >
              Docket No. 292 - Gainesville Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-293-ORDER-granting-284-Motion-for-Disbursement-of-Funds-re-MONTGOMERY-ALF-LLC-4839-9946.pdf"
            >
              Docket No. 293 - Montgomery Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-294-ORDER-granting-285-Motion-for-Disbursement-of-Funds-re-OPELIKA-ALF-LLC-4835-6431-03.pdf"
            >
              Docket No. 294 - Opelika Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-295-ORDER-granting-286-Motion-for-Disbursement-of-Funds-re-ROME-ALF-LLC-4822-8989-7333-.pdf"
            >
              Docket No. 295 - Rome Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-296-ORDER-granting-287-Motion-for-Disbursement-of-Funds-re-SAVANNAH-ALF-LLC-4840-1880-2.pdf"
            >
              Docket No. 296 - Savannah Motion for Disbursement of Funds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/10/Dkt-297-ORDER-granting-288-Motion-for-Disbursement-of-Funds-re-WATERFORD-PLACE-ALF-LLC-4842.pdf"
            >
              Docket No. 297 - Waterford Motion for Disbursement of Funds
              <br />
            </Link>
          </p>
          <p>
            9/24/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-281-Columbus-Partial-Distribution-Motion.pdf"
            >
              Docket No. 281 - Columbus Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-282-Douglas-Partial-Distribution-Motion.pdf"
            >
              Docket No. 282 - Douglas Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-283-Gainesville-Partial-Distribution-Motion.pdf"
            >
              Docket No. 283 - Gainesville Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-284-Montgomery-ALF-Partial-Distribution-Motion.pdf"
            >
              Docket No. 284 - Montgomery ALF Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-285-Opelika-Partial-Distribution-Motion.pdf"
            >
              Docket No. 285 - Opelika Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-286-Rome-Partial-Distribution-Motion.pdf"
            >
              Docket No. 286 - Rome Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-287-Partial-Distribution-Motion.pdf"
            >
              Docket No. 287 - Savannah Partial Distribution Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/Dkt-No.-288-Waterford-Place-ALF-Partial-Distribution-Motion.pdf"
            >
              Docket No. 288 - Waterford Partial Distribution Motion
              <br />
            </Link>
          </p>

          <p>
            9/05/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/09/119113690648d61f45ce.pdf"
            >
              Docket No.280 - Order Approving Waller Lansden Dortch and Davis
              Sixth Interim Fee Application for the period from April 1, 2018 to
              June 30, 2018 AND Order Approving Healthcare Management
              Partners Sixth Interim Fee Application for the period from April
              1, 2018 to June 30, 2018 
              <br />
            </Link>
          </p>

          <p>
            7/31/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/08/Dkt-No.-276-STATUS-REPORT-Sixth-Quarterly-Operating-Report-for-April-1-2018-through-June-30-1.pdf"
            >
              Docket No. 276 - Receiver's Sixth Quarterly Operating Report for
              the period of April 1, 2018 through June 30, 2018
              <br />
            </Link>
          </p>

          <p>
            5/15/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/05/Dkt-No.-268-Healthcare-Management-Partners-LLCs-Fifth-Interim-Fee-Application-with-Exhibits-and-Declaration-of-Service-.pdf"
            >
              Docket No. 268 - Healthcare Management Partners, LLC's Fifth
              Interim Fee Application (with Exhibits and Declaration of Service)
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/05/Dkt-No.-269-Elliott-Davis-LLCs-First-Interim-Fee-Application-with-Exhibits-and-Declaration-of-Service.pdf"
            >
              Docket No. 269 - Elliot Davis LLC's First Interim Fee Application
              (with Exhibits and Declaration of Service)
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/05/Dkt-No.-270-Waller-Lansden-Dortch-and-Davis-LLPs-Fifth-Interim-Fee-Application-with-Exhibits-and-Declaration-of-Service.pdf"
            >
              Docket No. 270 - Waller Lansden Dortch and Davis LLP's Fifth
              Interim Fee Application (with Exhibits and Declaration of Service)
              <br />
            </Link>
          </p>
          <p>
            5/1/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/05/Dkt.-264.-Receivers-Quarterly-Report-for-January-1-2018-through-March-31-2018-4823-2573-4756-v.1.pdf"
            >
              Docket No. 264 HMP SEC Receivership 17-cv-393 STATUS REPORT
              Receiver's Fourth Quarterly Operating Report
              <br />
            </Link>
          </p>
          <p>
            4/13/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/04/Dkt.-259-Motion-For-Order-Authorizing-Partial-Distribution-Of-Sale-Proceeds-4843-6506-6594-v2.pdf"
            >
              Docket No 259 - Motion for Order Authorizing Partial Distribution
              of Sale Proceeds
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/04/Dkt.-260-Motion-Unwinding-of-Commingled-Funds-4846-3350-2050-v2.pdf"
            >
              Docket No 260 - Motion Unwinding of Commingled Funds
              <br />
            </Link>
          </p>
          <p>
            4/10/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/04/Dkt.-254-Notice-by-Derek-Pierce-regarding-Notice-of-Closing-of-Sales-4850-8841-2769-v1.pdf"
            >
              Docket No 254 - Notice by Derek Pierce Regarding Notice of Closing
              of Sales
              <br />
            </Link>
          </p>
          <p>
            3/6/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b1-HMP-Columbus-Entered-Sale-Order.pdf"
            >
              Docket No 241 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Columbus
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b2-HMP-Douglas-Entered-Sale-Order.pdf"
            >
              Docket No 243 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Douglas
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b3-HMP-Gainesville-Entered-Sale-Order.pdf"
            >
              Docket No 244 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Gainesville
              <br />
            </Link>
            <Link target="_blank" to="">
              Docket No 245 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Montgomery
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b5-HMP-Opelika-Entered-Sale-Order.pdf"
            >
              Docket No 246 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Opelika
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b6-HMP-Rome-Entered-Sale-Order.pdf"
            >
              Docket No 247 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Rome
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b7-HMP-Savannah-Entered-Sale-Order.pdf"
            >
              Docket No 248 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Savannah
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b9-HMP-Waterford-Place-Entered-Sale-Order.pdf"
            >
              Docket No 249 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Waterford Place
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/03/b8-HMP-Social-Circle-Entered-Sale-Order.pdf"
            >
              Docket No 250 - Order Authorizing the Sale, Approving the Sale and
              Granting Related Relief for Social Circle
              <br />
            </Link>
          </p>
          <p>
            1/31/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/Dkt-211-2-Notice-of-Rescheduling-Hearing-by-DEREK-PIERCE-4844-5228-3995-v1.pdf"
            >
              Docket No 221-2 -Notice of Rescheduling of Hearing
              <br />
            </Link>
          </p>

          <p>
            1/9/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-205-ORDER-Authorizing-Sale-Douglas-Oxton-Place-4838-5787-5802-v1.pdf"
            >
              Docket No. 205 - Bidding Procedures Order for Douglas
              <br />
            </Link>
          </p>
          <p>
            1/8/18
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-196-ORDER-Granting-re-155-Social-Circle.pdf"
            >
              Docket No.196 - Bidding Procedures Order for Social Circle
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-197-ORDER-granting-re-159-Gainesville-v1.pdf"
            >
              Docket No.197 - Bidding Procedures Order for Gainesville
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-198-ORDER-granting-re-163-Columbus.pdf"
            >
              Docket No.198 - Bidding Procedures Order for Columbus
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-200-ORDER-granting-re-171-Opelika.pdf"
            >
              Docket No.200 - Bidding Procedures Order for Opelika
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-201-ORDER-granting-re-175-Application_Petition-4819-9159-9962-v.....pdf"
            >
              Docket No.201 - Bidding Procedures Order for Savannah
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-202-ORDER-granting-re-179-Application_Petition-4812-2004-4634-v.....pdf"
            >
              Docket No.202 - Bidding Procedures Order for Waterford Place
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-203-ORDER-granting-re-183-Application_Petition-4845-2849-8778-v.....pdf"
            >
              Docket No.203 - Bidding Procedures Order for Montgomery ALF
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2018/01/dkt-204-ORDER-granting-re-191-Application_Petition-4839-0793-8394-v.....pdf"
            >
              Docket No.204  - Bidding Procedures Order for Rome ALF
              <br />
            </Link>
          </p>
          <p>
            12/19/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Docket-171-Opelika-Receivers-Motion-re-bidding-procedures.pdf"
            >
              Docket No. 171 - Opelika Receiver Motion re: Bidding Procedures
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Docket-172-Opelika-Brief-in-Support-of-171.pdf"
            >
              Docket No. 172 - Opelika Brief in Support of Docket 171
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Docket-173-Opelika-Declaration.pdf"
            >
              Docket No. 173 - Opelika Declaration
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Docket-174-Opelika-Declaration-of-Service.pdf"
            >
              Docket No. 174 - Opelika Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-175-Savannah-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 175 - Savannah Bidding Procedures Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-176-Savannah-Brief.pdf"
            >
              Docket No. 176 - Savannah Brief
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-177-Savannah-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 177 - Savannah Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-178-Savannah-Declaration-of-Service.pdf"
            >
              Docket No. 178 - Savannah Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-179-Waterford-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 179 - Waterford Bidding Procedures Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-180-Waterford-Brief.pdf"
            >
              Docket No. 180 - Waterford Brief
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-181-Waterford-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 181 - Waterford Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-182-Waterford-Declaration-of-Service.pdf"
            >
              Docket No. 182 - Waterford Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-183-Montgomery-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 183 - Montgomery Bidding Procedures
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-184-Montgomery-Brief.pdf"
            >
              Docket No. 184 - Montgomery Brief
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-185-Montgomery-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 185 - Montgomery Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-186-Montgomery-Declaration-of-Service.pdf"
            >
              Docket No. 186 - Montgomery Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-187-Douglas-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 187 - Douglas Bidding Procedures Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-188-Douglas-Brief.pdf"
            >
              Docket No. 188 - Douglas Brief
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-189-Douglas-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 189 - Douglas Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-190-Douglas-Declaration-of-Service.pdf"
            >
              Docket No. 190 - Douglas Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-191-Rome-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 191 - Rome Bidding Procedures Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-192-Rome-Brief.pdf"
            >
              Docket No. 192 - Rome Brief
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-193-Rome-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 193 - Rome Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Dkt-194-Rome-Declaration-of-Service.pdf"
            >
              Docket No. 194 - Rome Declaration of Service
              <br />
            </Link>
          </p>
          <p>
            12/13/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/1-Dkt-155-Receivers-Motion-re-Bidding-Procedures.pdf"
            >
              Docket No. 155 - Social Circle Receivers Motion re Bidding
              Procedures
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/3-Dkt-157-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 157 - Social Circle Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/5-Dkt-158-Declaration-of-Service.pdf"
            >
              Docket No. 158 - Social Circle Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/2-Dkt-167-Brief-in-Support-of-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 167 - Social Circle Brief in Support of Bidding
              Procedures Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/4-Dkt-168-Exhibits-to-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 168 - Social Circle Exhibits to Declaration of D.
              Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Gainesville-1-Dkt-159-Receivers-Bidding-Procedures-Motion.pdf"
            >
              Docket No. 159 - Gainesville Receivers Bidding Procedures Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Gainesville-2-Dkt-169-Brief-in-Support-of-Receivers-Motion.pdf"
            >
              Docket No. 169 - Gainesville Brief in Support of Receivers Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Gainesville-3-Dkt-161-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 161 - Gainesville Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Gainesville-4-Dkt-170-Exhibits-to-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 170 - Gainesville Exhibits to Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/Gainesville-5-Dkt-162-Declaration-of-Service.pdf"
            >
              Docket No. 162 - Gainesville Declaration of Service
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/1-Columbus-Motion-for-Bidding-Procedures.pdf"
            >
              Docket No. 163 - Columbus Motion for Bidding Procedures
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/2-Columbus-Brief-in-Support-of-Motion.pdf"
            >
              Docket No. 164 - Columbus Brief in Support of Motion
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/3-Columbus-Declaration-of-D.-Pierce.pdf"
            >
              Docket No. 165 - Columbus Declaration of D. Pierce
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/12/4-Columbus-Declaration-of-Service.pdf"
            >
              Docket No. 166 - Columbus Declaration of Service
              <br />
            </Link>
          </p>
          <p>
            11/1/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/11/147-Receivers-Third-Quarterly-Operating-Report.pdf"
            >
              Docket No.147 HMP SEC Receivership 17-cv-393 STATUS REPORT
              Receiver's Third Quarterly Operating Report
              <br />
            </Link>
          </p>

          <p>
            8/15/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/08/Docket-No.-132-HMP-SEC-Receivership-17-cv-393-MOTION-for-Attorney-Fees-Wallers-Second-Interim.pdf"
            >
              Docket No. 132 - HMP SEC Receivership 17-cv-393 MOTION for
              Attorney Fees Waller's Second Interim
              <br />
            </Link>
          </p>
          <p>
            8/1/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/08/Second-Quarterly-Operating-Report-1.pdf"
            >
              Docket No. 128 - HMP SEC Receivership 2 17-cv-393 STATUS REPORT
              Receiver's Second Quarterly Operating Report
              <br />
            </Link>
          </p>
          <p>
            6/9/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/06/Docket-No.-125-HMP-SEC-Receivership-1-17-cv-393-FINAL-JUDGMENT-As-To-Relief-Defendant-Sharon-Nunamaker-4816-1589-0762-v1.pdf"
            >
              Docket No. 125 - HMP SEC Receivership 1 17-cv-393 FINAL JUDGMENT
              As To Relief Defendant Sharon Nunamaker 4816-1589-0762 v1
              <br />
            </Link>
          </p>
          <p>
            6/9/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/06/Docket-No.-124-HMP-SEC-Receivership-1-17-cv-393-JUDGMENT-as-to-Defendants-Dwayne-Edwards-Oxton-Senior-Living-and-Manor-House-Senior-Living.pdf)"
            >
              Docket No. 124 - HMP SEC Receivership 1 17-cv-393 JUDGMENT as to
              Defendants Dwayne Edwards, Oxton Senior Living and Manor House
              Senior Living
              <br />
            </Link>
          </p>
          <p>
            6/9/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/06/Docket-No.-123-HMP-SEC-Receivership-1-17-cv-393-ORDER-Dissolving-Asset-Freeze-As-To-Relief-Defendants-Sharon-Nunamaker-And-SDH-Design-LLC-.pdf"
            >
              Docket No. 123 - HMP SEC Receivership 1 17-cv-393 ORDER Dissolving
              Asset Freeze As To Relief Defendants Sharon Nunamaker And SDH
              Design, LLC
              <br />
            </Link>
          </p>
          <p>
            6/5/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/06/Docket-No.-122-HMP-SEC-Receivership-1-17-cv-393-Letter-from-Securities....pdf"
            >
              Docket No. 122 - HMP SEC Receivership 1 17-cv-393
              <br />
            </Link>
          </p>
          <p>
            6/1/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/06/Docket-No.-120-HMP-SEC-Receivership-1-17-cv-393-STIPULATION-of-Dismissal-as-to-Relief-Defendant-SDH-Design-LLC-4823-4220-6025-v1.pdf"
            >
              Docket No. 120 - HMP SEC Receivership 1 17-cv-393 STIPULATION of
              Dismissal as to Relief Defendant SDH Design, LLC 4823-4220-6025 v1
              <br />
            </Link>
          </p>
          <p>
            6/1/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/06/Docket-No.-119-HMP-SEC-Receivership-1-17-cv-393-STIPULATION-of-Dismissal-as-to-Relief-Defendant-Susan-Edwards-4833-3186-5161-v1.pdf"
            >
              Docket No. 119 - HMP SEC Receivership 1 17-cv-393 STIPULATION of
              Dismissal as to Relief Defendant Susan Edwards 4833-3186-5161 v1
              <br />
            </Link>
          </p>
          <p>
            5/4/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/05/17cv00393-Letter-from-SEC-re-Vacatur-of-State-Court-Receiver-Order-5-04-2017-DKT.-113.pdf"
            >
              17cv00393 Letter from SEC re Vacatur of State Court Receiver Order
              5-04-2017 (DKT. 113)
              <br />
            </Link>
          </p>
          <p>
            5/1/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/05/Docket-No.-109-HMP-SEC-REceivership-1-17-cv-393-STATUS-REPORT-Receivers-First-Quarterly-Opera.pdf"
            >
              Docket No. 109 - HMP SEC Receivership 1 17-cv-393 STATUS REPORT
              Receiver's First Quarterly Operating Report
            </Link>
          </p>
          <p>
            5/1/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/05/Docket-No.-107-HMP-SEC-REceivership-1-17-cv-393-Letter-from-Securities-and-Exchange-Commission-re-Vacatur-of-State-Court-Receiver-Order-re-.pdf"
            >
              Docket No. 107 - HMP SEC Receivership 1 17-cv-393 Letter from
              Securities and Exchange Commission re Vacatur of State Court
              Receiver Order
              <br />
            </Link>
          </p>
          <p>
            4/13/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/17cv00393-Letter-from-SEC-re-Vacatur-of-State-Court-Receiver-Order-4-13-2017-DKT.-101.pdf"
            >
              Dkt. No. 101 Notice That Oxton Court of Savannah is Now Subject to
              the Receiver Order Entered at Dkt. No. 7 on January 20, 2017
              <br />
            </Link>
          </p>
          <p>
            4/7/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/ndyzbwnsxvacan.pdf"
            >
              Dkt. No. 99 Notice that Manor House of Waterford Place is Now
              Subject to the Receiver Order Entered at Dkt. No. 7 on January 20,
              2017
              <br />
            </Link>
          </p>
          <p>
            3/29/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/Dkt-No.-82-ORDER-Approving-Employment-of-Healthcare-Management-Partners-4820-7915-7062-v.1.pdf"
            >
              Dkt No. 82 ORDER Approving Employment of Healthcare Management
              Partners 4820-7915-7062
              <br />
            </Link>
          </p>
          <p>
            3/29/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/Dkt-No.-83-ORDER-Approving-Employment-of-Waller-Lansden-4822-2930-0038-v.1.pdf"
            >
              Dkt No. 83 ORDER Approving Employment of Waller Lansden
              4822-2930-0038
              <br />
            </Link>
          </p>
          <p>
            3/29/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/Dkt-No.-84-ORDER-Approving-Employment-of-Elliott-Davis-Decosimo-4846-7916-6790-v.1.pdf"
            >
              Dkt No. 84 ORDER Approving Employment of Elliott Davis Decosimo
              4846-7916-6790
              <br />
            </Link>
          </p>
          <p>
            3/29/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/04/Dkt-No.-85-ORDER-granting-75-Application-Petition-for-Receivers-Application-for-Order-Approving-Employm-4828-6617-8886-v.1.pdf"
            >
              Dkt No. 85 ORDER granting 75 Application Petition for Receivers
              Application for Order Approving Employment 4828-6617-8886
              <br />
            </Link>
          </p>
          <p>
            2/6/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/New-Jersey-SEC-Case-No.-17-00393-dkt-23-Notice-of-Filing_.pdf"
            >
              New Jersey SEC Case No. 17-00393 dkt 23 - Notice of Filing,
              Pursuant to 28 U.S.C. 754, Complaint and Receiver Order
              <br />
            </Link>
          </p>
          <p>
            1/20/17
            <br />
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/01-20-17-SEC-v.-Edwards-Case-No.-2-17-cv-00393-Order-to-Show.pdf"
            >
              SEC v. Edwards Case No. 2-17-cv-00393 -  Order to Show Cause,
              Temporary Restraining Order and Order Freezing Assets and Granting
              Other Relief
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/01-20-17-SEC-v.-Edwards-Case-No.-2-17-cv-00393-Judgment-Barker_.pdf"
            >
              SEC v. Edwards Case No. 2-17-cv-00393 - Judgment - Barker
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/01-20-17-SEC-v.-Edwards-Case-No.-2-17-cv-00393-Receiver-Order_.pdf"
            >
              SEC v. Edwards Case No. 2-17-cv-00393 - Receiver Order
              <br />
            </Link>
            <Link
              target="_blank"
              to="../wp-content/uploads/2017/02/01-20-17-SEC-v.-Edwards-Case-No.-2-17-cv-00393-Complaint.pdf"
            >
              SEC v. Edwards Case No. 2-17-cv-00393- Complaint
              <br />
            </Link>
          </p>
        </div>
      </Layout>
    )
  }
}
