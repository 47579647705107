export const SideMenuItems = [
  { title: "General Information", Url: "/" },
  { title: "FAQS", Url: "/faqs" },
  { title: "About the Receiver", Url: "/about-the-receiver" },
  { title: "Message from the Receiver", Url: "/message-from-the-receiver" },
  { title: "Receiver Reports", Url: "/receiver-reports" },
  { title: "Key Court Documents", Url: "/key-court-documents" },
  { title: "Important Deadlines", Url: "/important-deadlines" },
  { title: "Assets for Sale", Url: "/assets-for-sale" },
  { title: "Third-party Settlement", Url: "/third-party-settlement" },
  { title: "Contact", Url: "/contact" },
]
